import { graphql, Link } from "gatsby"
import React from "react"
// import { makeBlogPath } from "../utils"
// import dateformat from "dateformat"
import moment from "moment";
import SEONOINDEX from '../components/seonoindex'
import Layout from '../components/Layout';
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import { SlugLink } from '../components/utils'
import Sidebar from '../components/Sidebar'
const baseuri = "https://dmf5.xyz"
class PostTag extends React.Component {
  renderTags(tags) {
    tags.map((item) => {
      //console.log(item)
      return <Button variant="outline-secondary">{item}</Button>
    })
  }
  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteUrl = data.site.siteMetadata.siteUrl
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.postbytag
    const tagg = data.privateGraphql.gettag
    const { currentPage, numPagest } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPagest
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    const MetaTitle = (tagg.metaname !== "" && tagg.metaname !== null) ? tagg.metaname : tagg.name;
    const MetaDescription = (tagg.description !== "" && tagg.description !== null) ? tagg.description : "Description  " + tagg.name;
    const tagHeader = (tagg.displayname != "" && tagg.displayname != null) ? tagg.displayname : `${tagg.name}`
    if (location != null) {
      if (location.href != null && location.href.endsWith('/')) {
        window.location.href = window.location.href.substring(0, window.location.href.length - 1);
      }
    }
    return (
      <Layout location={this.props.location} title={MetaTitle}>
        {posts.length > 0 ?
          <SEONOINDEX
            title={`${MetaTitle}`}
            description={`${tagg.count} Posts "${MetaDescription}"`}
            canonical={`https://${domainName}/${tagg.slugname}`}
            datePublished={posts.length > 0 ? posts[0].publishDate : null}
            dateModified={posts.length > 0 ? posts[0].modifiedDate : null}
            image={posts.length > 0 ? posts[0].image : ""}
          /> :
          <SEONOINDEX
            title={`${MetaTitle}`}
            description={`${tagg.count} Posts "${MetaDescription}"`}
            canonical={`https://${domainName}/${tagg.slugname}`}
          />}

        <div className="row d-flex justify-content-center">
          <header className="col-10 text-center">
            <h1 className="arhl">{tagHeader}</h1>
            <p className="wpc">
              {tagg.descriptionwithhtml ?
                <section dangerouslySetInnerHTML={{ __html: tagg.descriptionwithhtml }} />
                :
                `${tagg.count} Posts - "${MetaDescription}"`
              }
            </p>
          </header>
        </div>
        {tagg.headline02 !== "" ?
          <div class="row d-flex justify-content-center">
            <h2 className="col-10 text-center">
              <section dangerouslySetInnerHTML={{ __html: tagg.headline02 }} />
            </h2>
          </div> :
          (null)
        }
        <div className="row">
          <div className="col-lg-8">
            <div className="row equal">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="col-lg-12 col-md-12 psdd" key={node.slugtitle}>
                    <article className="row post-id-single">
                      <div className="col-lg-6 col-md-6 post-id-single-img">
                        <a className="post-id-single-link" tabIndex="-1" href={siteUrl + "/" + node.slugtitle} title={title}>
                          <figure className="post-id-single-figure">
                            <LazyLoadImage
                              className="post-id-single_image"
                              alt={title}
                              title={title}
                              src={node.image}
                              effect="blur"
                            />
                          </figure>
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 post-id_blurb">
                        <a className="post-id_link" href={siteUrl + "/" + node.slugtitle}>
                          <h2 className="post-id_headline-with-image">
                            {title}
                          </h2>
                        </a>
                        <div className="d-flex justify-content-start post-id_card">
                          <div className="post-id_image-row">
                            <div className="post-id_image-row-item">
                              <LazyLoadImage
                                className="post-id_image post-id_image-small"
                                title={node.profileName}
                                src={node.profileImage}
                                effect="blur"
                              />
                            </div>
                          </div>
                          <div className="post-id_name">
                            <Link to={`/authors/${node.profileSlugName}`}>{node.profileName}</Link>
                            <div className="post-id_published">
                              <time>{moment(node.publishDate).format('DD.MM.YYYY')}</time>
                            </div>
                          </div>
                        </div>
                        <div className="post-id_desc">
                          <div className="post-id_subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                        </div>
                        
                      </div>
                    </article>
                  </div>
            )
          })}
        </div>
        {tagg.textwithhtml ?
          <div className="row">
            <div className="col-12">
              <section className="tehml" dangerouslySetInnerHTML={{ __html: tagg.textwithhtml }} />
            </div>
          </div>
          :
          (null)}
        </div>
          <Sidebar/>
        </div>  
        <div className="row d-flex justify-content-center pagi-wrap">

            {!isFirst && (
              <div className="pagi__link">
                <Link to={curr <= 2 ? `/${tagg.slugname}` : `/${tagg.slugname}/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </div>
            )}
            {Array.from({ length: numPagest }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <div
                    key={`pagination-number${i + 1}`}
                    className="pagi__link"
                  >
                    <Link
                      to={`/${tagg.slugname}${i === 0 ? '' : '/page/' + (i + 1)}`}
                      className={i + 1 === curr ? "current" : "na-pagi"}
                    >
                      {i + 1}
                    </Link>
                  </div>
                )
              }
            })}
            {!isLast && (
              <div className="pagi__link">
                <Link to={`/${tagg.slugname}/page/${nextPage}`} rel="next">
                  →
                </Link>
              </div>
            )}
        </div>
      </Layout>
    )
  }
}
export default PostTag
export const query = graphql`
query ($tag: String,$limit: Int = 21, $skip: Int = 0, $siteId: Int = 1083) {
  site {
    siteMetadata {
      title
      domainName
      description
      siteUrl
    }
  }
  privateGraphql {
    gettag(slugtitle: $tag, siteId: $siteId) {
      id
      description
      image
      metaname
      name
      slugname
      descriptionwithhtml
      count
      textwithhtml
      headline02
      displayname
    }
    postbytag(
      slugtitle: $tag
      limit: $limit 
      skip: $skip
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        modifiedDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
